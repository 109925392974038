
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";

type Activity = {
  date: string;
  bookings: Booking[];
};

type Booking = {
  code: string;
  state: string;
  state_for_display: string;
  time_for_display: string;
  end_time_for_display: string;
  bookable: {
    activity: {
      title: string;
    };
  };
};

@Component({
  filters: {
    date(value, format, appLanguage) {
      if (!value) return;

      return moment(value)
        .parseZone()
        .locale(appLanguage)
        .format(format);
    }
  }
})
export default class MyActivitiesPage extends Vue {
  activities: Activity[] = [];

  activityDialodCode = "";
  cancelActivityDialog = false;

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  created() {
    this.fetchActivities();
  }

  fetchActivities() {
    this.$store.dispatch("mssngr/fetchActivities").then(response => {
      this.activities = response.data.data.groups;

      this.hideCancelActivityDialog();
    });
  }

  showCancelActivityDialog(code: string) {
    this.activityDialodCode = code;
    this.cancelActivityDialog = true;
  }

  hideCancelActivityDialog() {
    this.activityDialodCode = "";
    this.cancelActivityDialog = false;
  }

  cancelActivity(code: string) {
    this.$store.dispatch("mssngr/cancelActivity", code).then(this.fetchActivities);
  }
}
