
import { Component, Vue } from "vue-property-decorator";

import MyReservations from "./MyReservations.vue";
import MyActivities from "./MyActivities.vue";

@Component({
  components: { MyReservations, MyActivities }
})
export default class BookingPage extends Vue {
  tab = 0;
}
